import { IllegalHTMLFormatError } from '../errors';

function GHMobileFlyout(flyoutContainerElem) {
  this.$container = $(flyoutContainerElem);
  this.$menu = this.$container.find('ul.menu');
  this.$items = this.$menu.find('li.item');
  this.$itemContents = this.$container.find('.flyout-content');

  this.init();
}

GHMobileFlyout.prototype.init = function () {
  // by default, all content of menus are hidden until
  // clicked/tapped
  this.$itemContents.hide();
  this.$container.addClass('collapsed');

  this.ensureProperStructure();

  // for easy unbinding on destroy
  this.onItemClickHandler = this.onItemClick.bind(this);
  this.$items.click(this.onItemClickHandler);
};

GHMobileFlyout.prototype.ensureProperStructure = function () {
  const idealStructureErrorMessage =
    'Invalid Structure!\n' +
    'Proper Structure is:\n' +
    '<div>\n' +
    '  <ul class="menu">\n' +
    '    <li class="item" data-toggle="foo">Some Menu Item title</li>\n' +
    '    <li class="item" data-toggle="bar">Some Menu Item title 2</li>\n' +
    '  </ul>\n' +
    '  <div class="flyout-content" id="foo">Flyout content foo</div>\n' +
    '  <div class="flyout-content" id="bar">Flyout content bar</div>\n' +
    '</div>';

  // if it contains a `div.menu`, and `.item` that have a corresponding `.flyout-content`,
  // then structure is valid and exit out. Otherwise, throw an error.
  if (
    this.$menu.length === 1 &&
    this.$items.filter('[data-toggle]').length === this.$items.length &&
    this.$items.length > 0 &&
    this.$itemContents.length > 0
  ) {
    return;
  }

  throw new IllegalHTMLFormatError(idealStructureErrorMessage);
};

GHMobileFlyout.prototype.onItemClick = function (evt) {
  this.showMenuItemContent($(evt.currentTarget));
};

GHMobileFlyout.prototype.showMenuItemContent = function ($menuItem) {
  const id = $menuItem.data('toggle');
  const $targetContent = $('#' + id);

  if ($menuItem.hasClass('selected')) {
    $menuItem.removeClass('selected');
    this.$itemContents.hide();
    this.$container.addClass('collapsed');
  } else {
    this.$items.removeClass('selected');
    $menuItem.addClass('selected');
    this.$container.removeClass('collapsed');
    this.$itemContents.not($targetContent).hide();
    $targetContent.show();
  }
};

GHMobileFlyout.prototype.destroy = function () {
  this.$items.off('click', this.onItemClickHandler);
};

window.$.fn.ghMobileFlyout = function (options) {
  const pluginKey = 'ghMobileFlyout';

  return this.each(function () {
    const plugin = $.data(this, pluginKey);

    if (options !== 'destroy') {
      if (!plugin) {
        $.data(this, pluginKey, new GHMobileFlyout(this, options));
      }
    } else if (plugin) {
      plugin.destroy();
      $.removeData(this, pluginKey);
    }
  });
};
