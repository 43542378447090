import { IllegalHTMLFormatError } from '../errors';

function GHMobileTabsPlugin(tabContainerElem) {
  this.$ghTabsContainer = $(tabContainerElem);
  this.$tabs = this.$ghTabsContainer.find('.tabs .tab');

  this.init();
}

GHMobileTabsPlugin.prototype.init = function () {
  this.ensureProperStructure();

  // set the click handler but make sure to bind to correct context
  this.wrappedClickHandler = this.onTabLinkClick.bind(this);
  this.$tabs.click(this.wrappedClickHandler);

  this.setCurrentTab();
};

GHMobileTabsPlugin.prototype.ensureAccessibility = function (
  $tabs,
  $tabPanels
) {
  if (
    $tabs.filter('[role="tab"]').length === $tabs.length &&
    $tabPanels.filter('[role="tab-panel"]').length === $tabPanels.length
  ) {
    // automatically add a `aria-controls` by reading the <a> `href`
    $tabs.each(function () {
      const id = $(this).find('a').attr('href').substr(1);
      $(this).attr('aria-controls="' + id + '"');
    });
  } else {
    throw new IllegalHTMLFormatError(
      'All tabs have to have a ' +
        '`role="tab" and all tab content elements with a `role="tab-panel"` ' +
        'defined for accessibility'
    );
  }
};

GHMobileTabsPlugin.prototype.ensureProperStructure = function () {
  const idealStructureErrorMessage =
    'Invalid Structure!\n' +
    'Proper Structure is:\n' +
    '<div class="tabs">\n' +
    '  <li role="tab" class="tab"><a href="#tab1"></li>\n' +
    '  <li role="tab" class="tab"><a href="#tab2"></li>\n' +
    '</div>\n' +
    '<div class="tab-panels">\n' +
    '  <div role="tab-panel" id="tab1"></div>\n' +
    '  <div role="tab-panel" id="tab2"></div>\n' +
    '</div>';

  const $nav = this.$ghTabsContainer.find('.tabs');
  const $tabPanelsContainer = this.$ghTabsContainer.find('.tab-panels');
  const $tabPanels = $tabPanelsContainer.find('[role="tab-panel"]');

  if (
    $nav.length === 0 ||
    $nav.find('.tab').length === 0 ||
    $tabPanelsContainer.length === 0 ||
    $tabPanels.length === 0
  ) {
    throw new IllegalHTMLFormatError(idealStructureErrorMessage);
  }

  this.ensureAccessibility(this.$tabs, $tabPanels);
};

GHMobileTabsPlugin.prototype.setCurrentTab = function () {
  // Make sure that it doesn't affect the browser history or otherwise going back and then
  // going forward in the history would cause a jump to
  // the specific tab which should only happen on click.
  if (
    location.hash.length === '' ||
    this.$ghTabsContainer.find(location.hash).length === 0
  ) {
    this.setTab(this.$ghTabsContainer.find('.tabs .tab:first'), false);
  } else {
    const $tab = this.$ghTabsContainer
      .find('.tabs .tab a[href="' + location.hash + '"]')
      .closest('.tab');
    this.setTab($tab, false);
  }
};

/**
 * @param {boolean} setHistory - should the setting of the tab affect browser history
 * */
GHMobileTabsPlugin.prototype.setTab = function ($tab, setHistory) {
  const href = $tab.find('a').attr('href');

  if (setHistory) {
    // push the state to the history for convenience
    window.history.pushState(null, '', href);
  }

  $tab.addClass('active').attr('aria-selected', true);

  this.$tabs.not($tab).removeClass('active').removeAttr('aria-selected');

  const $tabPanels = this.$ghTabsContainer.find('[role="tab-panel"]');
  const $currentTabPanel = $tabPanels.filter(href).show();
  $tabPanels.not($currentTabPanel[0]).hide();
};

GHMobileTabsPlugin.prototype.onTabLinkClick = function (event) {
  // prevent the DOM from jumping up/down when the link is clicked
  event.preventDefault();

  this.setTab($(event.currentTarget), true);
};

GHMobileTabsPlugin.prototype.destroy = function () {
  if (this.$ghTabsContainer) {
    this.$tabs.off('click', this.wrappedClickHandler);
    delete this.$ghTabsContainer;
    delete this.$tabs;
  }
};

window.$.fn.ghMobileTabs = function (options) {
  const pluginKey = 'ghMobileTabs';
  return this.each(function () {
    const plugin = $.data(this, pluginKey);

    if (options !== 'destroy') {
      if (!plugin) {
        $.data(this, pluginKey, new GHMobileTabsPlugin(this, options));
      }
    } else if (plugin) {
      plugin.destroy();
      $.removeData(this, pluginKey);
    }
  });
};
