import Greenhouse from 'legacy/greenhouse';
export default (function (window) {
  window.Greenhouse = Greenhouse || {};
  window.Greenhouse.Translation = Greenhouse.Translation || {};

  Greenhouse.Translation = window.Greenhouse.Translation;
  Greenhouse.Translation.translations =
    window.Greenhouse.Translation.translations || {};

  return Greenhouse.Translation;
})(window);
